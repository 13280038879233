import DateTimeHelper from '@/services/DateTimeHelper';
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  data() {
    return {
      dates: [],
      datesStart: null,
      datesLength: null,
      sessionsIsLoading: true,
      nonWorkingDaysIsLoading: true
    }
  },
  computed: {
    ...mapState({
      nonWorkingDays: state => state.nonWorkingDays,
      selectedDate: state => state.order.date
    }),

    datePickerIsLoading() {
      return this.nonWorkingDaysIsLoading || this.sessionsIsLoading;
    }
  },
  methods: {

    ...mapActions(['selectDay']),
    ...mapMutations(['setNonWorkingDays']),

    buildDates() {
      if (!this.datesStart || !this.datesLength) return;

      const dates = [];

      for (let i=0; i<this.datesLength; i++) dates.push({
        date: DateTimeHelper.moscowDate(this.datesStart).add(i, 'day'),
        selected: false,
        sessions: {}
      });

      this.dates = dates;
    },

    loadNonWorkingDays() {
      this.nonWorkingDaysIsLoading = true;
      return this.api.getNonWorkingDays()
          .then(days => {
            this.setNonWorkingDays(days);
            this.nonWorkingDaysIsLoading = false;
          })
          .catch(() => {
            this.setNonWorkingDays([]);
            this.nonWorkingDaysIsLoading = false;
          })
    },

    loadSessions() {
      this.sessionsIsLoading = true;
      const rawDates = this.dates.map(day => day.date.toDate()); // from moment to js date

      return this.api.getSessionsByDates(rawDates).then(sessions => {
        this.mergeSessions(sessions);
        this.sessionsIsLoading = false;
      });
    },

    mergeSessions(sessions) {
      for (let i=0; i<this.dates.length; i++) this.$set(this.dates, i, { ...this.dates[i], sessions: sessions[i] || [] });
    },

    reloadDates() {
      this.buildDates();
      return this.loadSessions().then(() => this.selectFirstAvailableDayIfNeeded());
    },

    selectFirstAvailableDayIfNeeded() {
      if (!this.selectedDate) {
        console.log(this.dates);
        const firstAvailableDay = this.dates.find(day => !(this.noSessionsAvailableForDay(day) || this.isDayUnavailable(day)));
        if (firstAvailableDay) this.selectDay(firstAvailableDay);
      }
    },

    isDaySelected(day) {
      return DateTimeHelper.moscowDate(day.date).isSame(this.selectedDate, 'day')
    },

    isDayUnavailable(day) {
      return this.isNonWorkingDay(day) || this.isDayBeforeCurrent(day);
    },

    isNonWorkingDay(day) {
      return this.nonWorkingDays.some(d => d.isSame(day, 'day'));
    },

    isDayBeforeCurrent(day) {
      return day.date.isBefore(DateTimeHelper.moscowDate()) && !day.date.isSame(DateTimeHelper.moscowDate(), 'day');
    },

    noSessionsAvailableForDay(day) {
      return !Object.keys(day.sessions).length;
    }
  }
}
