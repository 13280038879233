import Vue from 'vue'
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';

import App from '@/App';
import Api from '@/services/Api';
import { i18n } from '@/i18n';
import { store } from '@/store';
import { router } from '@/router';

Vue.use(Api)
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueRouter);

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app-mount')
