<template>
  <div id="mobile-date-time-picker" class="container">
    <p class="subtitle">{{ $t('selectDateAndTime') }}</p>
    <div class="block">
      <loader
          :is-visible="datePickerIsLoading"
          :loaded-with-error="monthSessionsLoadingError"
          :error-message="$t('errorLoadingTickers')"
      ></loader>
      <v-simple-calendar
          :value="vcsDate"
          :type="vcsType"
          :locale="vcsLocale"
          :min-date="vcsMinDate"
          :month-format="vcsMonthFormat"
          :weekday-format="vcsWeekdayFormat"
      >
        <template v-slot:day="{day}">
          <div
            class="vcs-table__day"
            :class="{
              selected: isDaySelected(day),
              unavailable: datePickerIsLoading || isVcsDayUnavailable(day)
            }"
            @click="selectVcsDay(day)"
          >
            {{day | formatDay}}
          </div>
        </template>
        <template v-slot:arrow-left>
          <div
              class="arrow arrow-left"
              :class="{disabled: !isPreviousMonthAvailable}"
              @click="minusMonth"
          ></div>
        </template>
        <template v-slot:arrow-right>
          <div
              class="arrow arrow-right"
              @click="plusMonth"
          ></div>
        </template>
      </v-simple-calendar>
      <time-picker class="time-picker"></time-picker>
    </div>
  </div>
</template>

<script>
import { ru, en, zhCN } from 'date-fns/locale'
import VSimpleCalendar from '@romanran/vue-simple-calendar';
import '@romanran/vue-simple-calendar/dist/vue-simple-calendar.css';
import DateTimeHelper from "@/services/DateTimeHelper";
import DatePicker from "@/mixins/DatePicker";
import TimePicker from "@/components/TimePicker";
import Loader from "@/components/Loader";

export default {
  name: "MobileDateTimePicker",
  mixins: [DatePicker],
  components: {
    Loader,
    TimePicker,
    VSimpleCalendar
  },
  data() {
    return {
      vcsType: 'single',
      vcsMinDate: new Date(),
      vcsMonthFormat: 'MMM',
      vcsLocale: this.getDateFnsLocale(),
      monthSessionsLoadingError: false,

      vcsDate: null,
      monthNumber: null
    }
  },
  filters: {
    formatDay: (day) => DateTimeHelper.moscowDate(day.date).format('DD')
  },
  computed: {
    month() {
      return this.dates;
    },
    isPreviousMonthAvailable() {
      return this.monthNumber > DateTimeHelper.moscowDate().month();
    },

    vcsWeekdayFormat() {
      return this.$i18n.locale === 'en' ? 'eee' : 'eeeeee';
    }
  },
  methods: {
    getDateFnsLocale() {
      switch (this.$i18n.locale) {
        case 'ru': return ru;
        case 'en': return en;
        case 'zh': return zhCN;
      }
    },
    plusMonth() {
      this.monthNumber++;
    },
    minusMonth(event) {
      if (this.isPreviousMonthAvailable) {
        this.monthNumber--;
      } else {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    },

    isVcsDayUnavailable(vcsDay) {
      const monthDay = this.findMonthDay(vcsDay)
      return !monthDay || this.noSessionsAvailableForDay(monthDay) || this.isDayUnavailable(monthDay);
    },

    selectVcsDay(vcsDay) {
      if (this.isVcsDayUnavailable(vcsDay)) return;
      const monthDay = this.findMonthDay(vcsDay);
      if (monthDay) this.selectDay(monthDay);
    },

    findMonthDay(vcsDay) {
      return this.month.find(d => d.date.isSame(DateTimeHelper.moscowDate(vcsDay.date), 'day'));
    }
  },
  created() {
    this.loadNonWorkingDays().then(() => this.monthNumber = DateTimeHelper.moscowDate().month());
  },
  watch: {
    monthNumber() {
      this.datesStart = DateTimeHelper.moscowStartOfMonth(this.monthNumber);
      this.datesLength = DateTimeHelper.moscowDate().daysInMonth();
      this.reloadDates().catch(() => this.monthSessionsLoadingError = true);
    },
    '$root.$i18n.locale'() {
      this.vcsLocale = this.getDateFnsLocale();
    }
  }
}
</script>

<style lang="scss">
@import "../styles/colors";

#mobile-date-time-picker {

  .block {
    padding: 0 1em;
    flex-direction: column;
  }

  .vcs {
    width: 100%;
    font-weight: bold;
  }

  .vcs-select-bar {

    align-items: center;
    padding: 1em 0.5em 2em;
    width: unset;

    &__date {
      order: 1;
      text-align: left;
      text-transform: capitalize;
    }

    &__arrow--left {
      order: 2;
    }

    &__arrow--right {
      order: 3;
    }

    .arrow {
      $arrow-size: 0.7em;

      min-width: $arrow-size;
      min-height: $arrow-size;
      max-width: $arrow-size;
      max-height: $arrow-size;

      border-left: 3px solid black;
      border-top: 3px solid black;
      box-sizing: border-box;

      &-left {
        transform: rotate(-45deg);
      }

      &-right {
        transform: rotate(135deg);
      }

      &.disabled {
        border-color: rgba(0,0,0, 0.45);
      }
    }
  }

  .vcs-table {

    width: 100%;

    &__day,
    &__weekday {
      width: calc((100vw - 4em) / 7);
      height: calc((100vw - 4em) / 7);
    }

    &__day {
      display: flex;
      justify-content: center;
      align-items: center;

      color: black;
      background: unset;

      &:hover,
      &:active {
        color: $green;
      }

      &.unavailable {
        color: rgba(0,0,0, 0.45);
      }

      &.selected {
        background: unset;
        border: 3px solid $green;
        border-radius: calc((100vw - 8em) / 7);
        color: $green;
      }
    }

    &__weekday {
      text-transform: uppercase;
    }
  }

  .time-picker {
    padding-top: calc(1em - 8px);
    padding-bottom: calc(1em - 8px);
  }
}
</style>
