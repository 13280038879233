<template>
  <div class="privacy-policy-link">
    <p><a href="https://yauza-epc.ru/privacypol">{{ $t('privacyPolicy') }}</a></p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyLink'
};
</script>

<style lang="scss" scoped>
  .privacy-policy-link {
    margin-top: 32px;
  }
</style>
