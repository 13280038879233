import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    order: {
      date: null,
      time: null,
      email: null,
      tickets: {}
    },
    nonWorkingDays: [],
    selectedDaySessions: {},
    selectedTimeTariffs: [],

    usingPortraitMobile: false,
    usingLandscapeMobile: false
  },
  getters: {
    isMobile: state => state.usingPortraitMobile || state.usingLandscapeMobile,
    overallAmount: state => Object.values(state.order.tickets).reduce((sum, position) => sum + position.tariff.price * position.quantity, 0),
    orderedQuantity: state => ({id}) => state.order.tickets[id] && state.order.tickets[id].quantity || 0
  },
  mutations: {
    checkIsMobile: (state) => {
      state.usingPortraitMobile = window.innerWidth < 600;
      state.usingLandscapeMobile = window.innerWidth > 600 && window.innerWidth < 720
    },
    setNonWorkingDays: (state, days) => state.nonWorkingDays = days,
    setSelectedDaySessions: (state, sessions) => state.selectedDaySessions = sessions,
    setSelectedTimeTariffs: (state, tariffs) => state.selectedTimeTariffs = tariffs,

    setDate: (state, date) => state.order.date = date,
    setTime: (state, time) => state.order.time = time,
    setEmail: (state, email) => state.order.email = email,

    addTickets: (state, {tariff, quantity}) => Vue.set(state.order.tickets, tariff.id, { tariff, quantity }),
    dropTickets: (state, tariff) => Vue.delete(state.order.tickets, tariff.id),
    clearTickets: (state) => state.order.tickets = {}
  },
  actions: {
    selectDay: ({commit, state, dispatch}, {date, sessions}) => {
      commit('setDate', date.toDate());
      commit('setSelectedDaySessions', sessions)

      const allSessions = Object.keys(sessions);
      const firstSession = allSessions[0];

      dispatch('selectTime', allSessions.includes(state.order.time) ? state.order.time : firstSession);
    },
    selectTime: ({state, commit}, time) => {
      commit('setTime', time);
      commit('clearTickets');
      commit('setSelectedTimeTariffs', state.selectedDaySessions[time]);
    },
    changeQuantity: ({commit}, {tariff, quantity}) => {
      if (quantity > 0) {
        commit('addTickets', {tariff, quantity})
      } else {
        commit('dropTickets', tariff);
      }
    }
  }
});