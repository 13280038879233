<template>
  <div id="mobile-cart" class="container" v-if="orderIsNotEmpty">
    <p class="subtitle">{{ $t('checkout') }}</p>
    <div class="block">
      <ul class="positions">
        <li class="position" v-for="position in order.tickets">
          <span>{{ $t(position.tariff.name) }}</span>
          <counter
            :value="position.quantity"
            :editable="true"
            @on-change="quantity => changeQuantity({tariff: position.tariff, quantity})"
          ></counter>
        </li>
        <li class="overall">
          <span class="name">{{ $t('overallAmount') }}</span>
          <span class="qty">{{ overallAmount }}₽</span>
        </li>
      </ul>
      <order-form @change-quantity="clearTickets"></order-form>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import OrderForm from "@/components/OrderForm";
import Counter from './Counter.vue';

export default {
  name: "MobileCart",
  components: { OrderForm, Counter },
  computed: {

    ...mapState(['order']),

    ...mapGetters(['overallAmount']),

    orderIsNotEmpty() {
      return Object.keys(this.order.tickets).length;
    }
  },
  methods: {
    ...mapMutations(['clearTickets']),
    
    ...mapActions(['changeQuantity']),
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

#mobile-cart {
  .block {
    color: white;
    padding: 28px;
    background: -webkit-linear-gradient(left, $green, $blue);
    flex-direction: column;

    ul.positions {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;

      & > li {
        display: flex;
        padding: 16px 0;
        flex-wrap: nowrap;
        justify-content: space-between;

        > span:first-child {
          width: calc(75% - 0.5em);
        }

        &.position {
          border-bottom: 1px solid rgba(255,255,255, 0.45);
        }

        &.overall {
          font-size: 0.8em;
          font-weight: bolder;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "../styles/colors";

#mobile-cart #order-form {
  width: 100%;

  margin-top: 2em;

  > * {
    width: 100%;
    margin-bottom: 8px;
  }

  button {
    opacity: 1;
    font-size: 1em;

    border-color: $white;
    background: $white;
    color: $green;

    &:not(:disabled):hover,
    &:not(:disabled):active {
      border-color: $white;
      background: none;
      color: $white;
    }
  }

  input {
    color: $white;
    border-color: $white;
    background: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
  }

  a {
    color: $white !important;
  }
}

#mobile-cart {
  
  #counter button {
    color: $white;
    border: solid 2px $white;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    margin: 0 8px;
    font-size: 11px;
    line-height: 10px;
    padding: 0;
  }

}
</style>