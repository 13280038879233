<template>
  <div id="mobile-tariffs-table" class="container">
    <p class="subtitle">{{ $t('selectTypeAndQuantity') }}</p>
    <div class="tariffs-wrapper">
      <div v-for="tariff in tariffs" class="tariff-card">
        <span class="bold-text">{{ $t(tariff.name) }}</span>
        <div class="footer">
          <span class="bold-text price">{{tariff.price}}<span class="r">₽</span></span>
          <div v-if="!tariff.preferential" class="icon cart add-to-cart" @click="onAddToCartClicked(tariff)">
            <div
              v-if="$store.getters.orderedQuantity(tariff) > 0" 
              class="quantity"
            >
              {{$store.getters.orderedQuantity(tariff)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: "MobileTariffsTable",
  computed: mapState({
    tickets: state => state.order.tickets,
    tariffs: state => state.selectedTimeTariffs,
  }),
  methods: {
    ...mapMutations(['addTickets']),

    onAddToCartClicked(tariff) {
      if (!tariff.preferential) {
        const quantity = this.$store.getters.orderedQuantity(tariff) + 1;
        this.addTickets({tariff, quantity});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/icons";
@import "../styles/colors";

#mobile-tariffs-table {
  .tariffs-wrapper {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tariff-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      width: calc(50% - 0.5em);
      margin: 0.5em 0;
      min-height: 200px;
      background: $white;
      border: 3px solid $grey;
      border-radius: 28px;
      padding: 1em;

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-size: 1.3em;

          .r {
            margin-left: 2px;
            font-family: sans-serif;
            font-weight: 900;
          }
        }

        .add-to-cart {
          font-weight: 700;
          font-size: 1.2em;
          display: flex;

          &:hover,
          &:active {
            color: $green;
          }

          &.disabled {
            color: rgba(0,0,0, 0.45);
          }
        }

        .quantity {
          $size: 16px;
          max-width: $size;
          min-width: $size;
          max-height: $size;
          min-height: $size;
          line-height: $size;
          border-radius: $size;
      
          background: white;
          border: 2px solid black;
          color: black;
          font-weight: bolder;
          text-align: center;
          font-size: 10px;
          position: relative;
          top: -8px;
          left: -4px;
        }
      }
    }
  }
}
</style>