<template>
  <transition name="fade">
    <div class="loader-wrapper" :class="{'error': loadedWithError}" v-if="isVisible">
      <div class="loader" v-if="!loadedWithError"></div>
      <div class="error-message" v-else v-html="errorMessage"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    loadedWithError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";
@import "../styles/transitions";

.loader-wrapper {
  position: absolute;
  transition: visibility 0.5s;
  background: rgba(255,255,255,0.7);
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  z-index: 2;

  &.error {
    background: rgba(255,255,255,0.9);
    align-items: center !important;
    padding: unset !important;
  }

  .loader {
    border: 1em solid;
    border-color: $grey;
    border-top-color: $blue;
    border-radius: 50%;
    width: 7em;
    height: 7em;
    animation: spin 2s linear infinite;
  }

  .error-message {
    text-align: center;
    line-height: 1.5;
    background-color: $blue;
    color: $white;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold
  }

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
