import VueRouter from 'vue-router';
import TicketsPage from '@/components/pages/TicketsPage';
import PaymentResultPage from '@/components/pages/PaymentResultPage';

export const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/ru/'},
    { path: '/:lang/', component: TicketsPage },
    { path: '/:lang/payment-result/:result', component: PaymentResultPage}
  ]
});