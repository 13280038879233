<template>
  <div id="payment-result">
    <div class="result-wrapper">
      <span class="bold-text" :class="{'gradient-text': paymentWasSuccessful}">{{ resultTitle }}</span>
      <span>{{ resultMessage }}</span>
      <div v-if="$route.query.orderId">
        <span v-if="!ticketIsReady">{{ $t('linkToTicketPreparing') }}</span>
        <button v-else class="button" @click="downloadTicket">{{ $t('downloadTickets') }}</button>
      </div>
      <router-link to="/">{{ $t('backToMainPage') }}</router-link>
    </div>
    <privacy-policy-link></privacy-policy-link>
  </div>
</template>

<script>
import PrivacyPolicyLink from '../PrivacyPolicyLink';

export default {
  name: 'PaymentResultPage',
  components: { PrivacyPolicyLink },
  data() {
    return { ticketIsReady: false }
  },
  computed: {
    paymentWasSuccessful() {
      return this.$route.params.result === 'success';
    },

    resultTitle() {
      return this.paymentWasSuccessful ? this.$t('paymentSuccess') : this.$t('paymentError');
    },

    resultMessage() {
      return this.paymentWasSuccessful ? this.$t('ticketsWasSentToEmail') : this.$t('pleaseWaitAndTryAgain');
    }
  },
  methods: {
    downloadTicket() {
      this.api.downloadTicket(this.$route.query.orderId);
    }
  },
  created() {
    if (this.$route.query.orderId) {
      this.api.waitUntilTicketIsReady(this.$route.query.orderId).then(() => this.ticketIsReady = true)
    }
  }
}
</script>

<style lang="scss" scoped>
#payment-result {

  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
  max-height: 50vh;

  .result-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > * {
      margin-bottom: 22px;
    }

    .bold-text {
      font-size: 2.7em;
    }

    .button {
      border-radius: 18px;
      min-width: 285px;
      min-height: 64px;
      font-size: 1em;
    }
  }
}
</style>
