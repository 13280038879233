<template>
  <div id="counter">
    <button :disabled="!value || !editable" @click="subtract">-</button>
    <span>{{ value }}</span>
    <button :disabled="!editable" @click="add">+</button>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    value: Number,
    editable: Boolean
  },
  methods: {
    add() {
      this.onChange(this.value + 1);
    },

    subtract() {
      this.onChange(this.value - 1);
    },

    onChange(value) {
      this.$emit('on-change', value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

#counter {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  button {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    background: transparent;
    font-size: 22px;
    line-height: 24px;
    box-sizing: border-box;
    border: 3px solid black;
    border-radius: 30px;

    &:disabled {
      cursor: text;
      color: black;
      opacity: 0.45;
    }

    &:not(:disabled):hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &:not(:disabled):active {
      color: $green;
      border-color: $green;
    }
  }
}
</style>