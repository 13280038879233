import { EffiProtocol, format_effi_date } from '@/lib/effi_protocol';
import moment from 'moment-timezone';
import lodash from 'lodash';
import DateTimeHelper from "@/services/DateTimeHelper";

const PAYMENT_RESULT = {
  FAIL: 'fail',
  SUCCESS: 'success'
}

class Api {

  host = 'https://bilet-yauza-epc.ru';
  rootUrl = '/nologin/srv/Baloon/';

  effi = new EffiProtocol({ host: this.host });

  getTariffLimit(date, tariffId) {
    return this.request(
        'WeekTariff/GetTariffLimit',
        `date=ADate:s:${format_effi_date(date)}&&tariff_id=i:${tariffId}`
        );
  }

  getSessionsByDates(dates) {
    return Promise.all(dates.map(date => this.getSessionsByDate(date)));
  }

  getNonWorkingDays() {
    return this
      .request('Holiday/HolidaysFromDateListGet_FE', this.formatDateParam(new Date()))
      .then((days) => (days || []).map(day => moment(day.adate)));
  }

  getSessionsByDate(date) {
    return this.request('WeekTariff/SeansesByDateListGet_FE', this.formatDateParam(date))
      .then(sessions => (sessions || []).map(s => ({
        id: s['id'],
        name: s['name'],
        price: s['price'],
        time: this.parseTime(date, s['time_from']),
        preferential: s['pricetype'] === 'true'
      })))
      .then(sessions => sessions.filter(s => s.time.isAfter(moment())))
      .then(sessions => lodash.groupBy(sessions, 'time'));
  }

  buyTickets(order) {
    return this.placeAnOrder(order)
      .then(orderResponse => this.raiseInvoice(orderResponse))
      .then(invoiceResponse => window.location.replace(invoiceResponse['action_url']));
  }

  placeAnOrder(order) {
    return this.request('PersonOrder/PlaceAny_FE', this.formatOrderParams(order))
  }

  raiseInvoice(orderPlacingResponse) {
    return this.request('PersonInvoice/RaiseInvoice_FE', this.formatInvoiceParams(orderPlacingResponse))
  }

  waitUntilTicketIsReady(orderId) {
    const ticketUrl = this.formatTicketURI(orderId);
    const awaitTimeout = 2000;

    return new Promise(resolve => {

      const redoRequest = () => setTimeout(() => resolve(this.waitUntilTicketIsReady(orderId)), awaitTimeout);

      fetch(ticketUrl)
        .then((response) => response.status === 200 ? resolve() : redoRequest())
        .catch(() => redoRequest());
    });
  }

  downloadTicket(orderId) {
    window.location.assign(this.formatTicketURI(orderId));
  }

  request(relativeUrl, payload) {
    return this.effi.request({
      url: this.rootUrl + relativeUrl,
      data: payload,
      crossDomain: true
    });
  }

  formatOrderParams(order) {
    let orderDesc = '';
    Object.keys(order.tickets).forEach(key => orderDesc += `${key}:${order.tickets[key].quantity}:`);
    return `email=s:${order.email}&${this.formatDateParam(order.date)}&&orderdesc=s:${orderDesc}`;
  };

  formatInvoiceParams = (orderResponse) => {
    const orderId = orderResponse.id;
    const amount = orderResponse.amount;

    return (
      `orderid=i:${orderId}&` +
      `amount=decimal:s:${amount}&&` +
      `success_url=s:${this.formatPaymentResultURI(PAYMENT_RESULT.SUCCESS)}&` +
      `fail_url=s:${this.formatPaymentResultURI(PAYMENT_RESULT.FAIL)}&`
    );
  }

  formatPaymentResultURI(result) {
    const slash = location.href.endsWith('/') ? '' : '/';
    return encodeURIComponent(`${location.href}${slash}payment-result/${result}`)
  }

  formatTicketURI(orderId) {
    return this.host + this.rootUrl + `PersonOrder/Download_FE?trxid=s:${orderId}&`;
  }

  formatDateParam(date) {
    return `adate=ADate:s:${format_effi_date(date)}`;
  }

  parseTime(date, timeString) {
    return DateTimeHelper
      .moscowDate(date)
      .add(timeString.substr(0, 2), 'hours')
      .add(timeString.substr(2, 2), 'minutes')
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.api = new Api();
  }
}
