<template>
  <div id="tickets-page">
    <page-title></page-title>
    <template v-if="usingPortraitMobile">
      <mobile-date-time-picker></mobile-date-time-picker>
      <mobile-tariffs-table></mobile-tariffs-table>
      <mobile-cart></mobile-cart>
    </template>
    <template v-else>
      <date-time-picker></date-time-picker>
      <tariffs-table></tariffs-table>
    </template>
    <page-footer></page-footer>
  </div>
</template>

<script>
import MobileCart from '@/components/MobileCart';
import MobileTariffsTable from '@/components/MobileTariffsTable';
import MobileDateTimePicker from '@/components/MobileDateTimePicker';
import PageTitle from '@/components/PageTitle';
import DateTimePicker from '@/components/DateTimePicker';
import TariffsTable from '@/components/TariffsTable';
import PageFooter from '@/components/PageFooter';
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'TicketsPage',
  components: {
    MobileCart,
    MobileTariffsTable,
    MobileDateTimePicker,
    PageTitle,
    DateTimePicker,
    TariffsTable,
    PageFooter
  },
  computed: mapState(['usingPortraitMobile']),
  methods: mapMutations(['checkIsMobile']),
  created() {
    this.checkIsMobile();
    addEventListener('resize', () => this.checkIsMobile());
  }
}
</script>

<style scoped>

</style>