<template>
  <div id="app" class="regular-text">
    <locale-switcher></locale-switcher>
    <router-view></router-view>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: 'App',
  components: { LocaleSwitcher },
  created() {
    this.$i18n.locale = this.$route.params.lang
  }
}
</script>

<style lang="scss">
@import "styles/fonts";
@import "styles/common";
@import "styles/responsive";

body {

  max-width: 100vw;
  margin: 0 !important;

  @include for-phone-only {
    font-size: 14px;
  }

  @include for-tablet-portrait-up {
    font-size: 12px;
  }

  @include for-tablet-landscape-up {
    font-size: 14px;
  }

  @include for-desktop-up {
    font-size: 16px;
  }

  @include for-big-desktop-up {
    font-size: 18px;
  }
}

#app {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include for-phone-only {
    padding: 1em;
  }

  @include for-tablet-portrait-up {
    padding: 1em;
  }

  @include for-tablet-landscape-up {
    max-width: 950px;
  }

  @include for-desktop-up {
    max-width: 1050px;
  }

  @include for-big-desktop-up {
    max-width: 1200px;
  }
}
</style>
