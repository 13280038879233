<template>
  <div id="page-footer" class="container">
    <p v-html="$t('contactsLabel', contactsLabelParams)"></p>
    <p class="addition1">{{ $t('contactsLabelAddition1') }}</p>
    <p class="addition2">{{ $t('contactsLabelAddition2') }}</p>
    <privacy-policy-link></privacy-policy-link>
    <p>{{ $t('contacts') }}</p>
  </div>
</template>

<script>

import PrivacyPolicyLink from './PrivacyPolicyLink';
const TEL_MAIN = '+7 (499) 476-22-59';
const TEL_EXT = '1';
const EMAIL = 'ecocenter.yauza@gmail.com';

export default {
  name: "PageFooter",
  components: {PrivacyPolicyLink},
  computed: {
    contactsLabelParams() {
      return {
        email: EMAIL,
        telValue: `${TEL_MAIN},${TEL_EXT}`,
        telLabel: this.$t('phoneNumber', { main: TEL_MAIN, ext: TEL_EXT }),
      }
    }
  }
}
</script>

<style lang="scss">
@import "../styles/colors";

#page-footer {

  .addition1::before {
    content: '*';
    color: $green;
  }

  .addition2::before {
    content: '**';
    color: $green;
  }
}
</style>
