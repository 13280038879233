<template>
  <span id="page-title" class="bold-text gradient-text">{{ $t('getTickets') }}</span>
</template>

<script>
export default {
  name: "PageTitle"
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";
@import "../styles/responsive";

#page-title {

  @include for-phone-only {
    font-size: 2.5em;
  }

  @include for-tablet-portrait-up {
    font-size: 3.5em;
  }
}
</style>