<template>
  <div id="date-time-picker" class="container">
    <div class="background">
      <img :src="logoImage" alt="logo">
    </div>
    <p class="subtitle">{{ $t('selectDateAndTime') }}</p>
    <div class="block">
      <loader
          :is-visible="datePickerIsLoading"
          :loaded-with-error="weekSessionsLoadingError"
          :error-message="$t('errorLoadingTickers')"
      ></loader>
      <div
          class="icon arrow arrow-left"
          :class="{disabled: !isPreviousWeekAvailable}"
          @click="onMinusWeekClick"
      ></div>
      <div class="date-time-picker">
        <div class="datepicker">
          <div
              v-for="day in week"
              class="day"
              :class="{
                selected: isDaySelected(day),
                unavailable: noSessionsAvailableForDay(day) || isDayUnavailable(day)
              }"
              @click="onDayClick(day)"
          >
            <div class="day-name bold-text">{{ day.date.format(shortDayFormatByLocale) }}</div>
            <div class="day-number bold-text">{{ day.date.format('D') }}</div>
            <div class="month-name">{{ day | formatMonthName(usingLandscapeMobile) }}</div>
          </div>
        </div>
        <time-picker class="time-picker" v-if="selectedDate"></time-picker>
      </div>
      <div class="icon arrow arrow-right" @click="onPlusWeekClick"></div>
    </div>
  </div>
</template>

<script>
import logoImage from '../assets/logo.svg';
import moment from 'moment-timezone';
import Loader from '@/components/Loader';
import TimePicker from '@/components/TimePicker';
import DateTimeHelper from '@/services/DateTimeHelper'
import {mapState} from 'vuex';
import DatePicker from "@/mixins/DatePicker";

export default {
  name: "DateTimePicker",
  components: {
    Loader,
    TimePicker
  },
  mixins: [DatePicker],
  filters: {
    formatMonthName: (day, useShortening) => useShortening
        ? day.date.format('MMM')
        : day.date.format('D MMMM').split(' ')[1]
  },
  data() {
    return {
      logoImage,
      weekNumber: null,
      weekSessions: [],
      weekSessionsIsLoading: true,
      weekSessionsLoadingError: false,
      firstAvailableWeekNumber: DateTimeHelper.moscowDate().week()
    }
  },
  computed: {
    ...mapState(['usingLandscapeMobile']),

    week() {
      return this.dates
    },

    isPreviousWeekAvailable() {
      return this.weekNumber > this.firstAvailableWeekNumber;
    },

    shortDayFormatByLocale() {
      return this.$i18n.locale === 'en' ? 'ddd' : 'dd';
    },

    datePickerIsLoading() {
      return this.nonWorkingDaysIsLoading || this.sessionsIsLoading;
    }
  },
  methods: {

    onPlusWeekClick() {
      this.weekNumber ++;
    },

    onMinusWeekClick() {
      if (this.isPreviousWeekAvailable) this.weekNumber --;
    },

    onDayClick(day) {
      if (!this.noSessionsAvailableForDay(day) && !this.isDayUnavailable(day)) this.selectDay(day);
    },

    selectNextWeekIfNeeded() {
      if (!this.selectedDate) {
        if (this.weekNumber === this.firstAvailableWeekNumber) this.firstAvailableWeekNumber ++;
        this.weekNumber ++;
      }
    }
  },
  watch: {
    weekNumber() {
      this.datesStart = DateTimeHelper.moscowStartOfWeek(this.weekNumber);
      this.datesLength = 7;
      this.reloadDates()
          //.then(() => this.selectNextWeekIfNeeded()) TODO
          .catch(() => {
            this.weekSessionsIsLoading = false;
            this.nonWorkingDaysIsLoading = false;
            this.weekSessionsLoadingError = true;
          })
    }
  },
  created() {
    this.loadNonWorkingDays().then(() => this.weekNumber = DateTimeHelper.moscowDate().week());
  },
  beforeCreate() {
    const getMomentLocale = () => {
      switch (this.$i18n.locale) {
        case 'en': return 'en-us';
        case 'zh': return 'zh-cn';
        default: return 'ru-ru';
      }
    }
    moment.locale(getMomentLocale());
  }
}
</script>

<style lang="scss">
@import "../styles/icons";
@import "../styles/colors";
@import "../styles/responsive";

#date-time-picker {

  .background {
    position: absolute;
    right: -115px;
    top: -15px;
    z-index: -1;

    @media (max-width: 1400px) {
      right: -3em;
    }

    @media (max-width: 1250px) {
      right: -1em;
    }
  }

  .block {

    padding: 0;
    min-height: 16em;

    .loader-wrapper {
      align-items: flex-start;
      padding-top: 4em;
    }

    .arrow {
      font-size: 2.3em;
      max-width: 60px;

      &:not(.disabled):hover {
        cursor: pointer;
        opacity: 0.7;
      }

      &:not(.disabled):active {
        color: $green, 10;
      }

      &.disabled {
        opacity: 0.45;
      }

      @include for-tablet-portrait-up {
        &.arrow-left {
          padding: 3em 0 0 0.5em;
        }

        &.arrow-right {
          padding: 3em 0.5em 0 0;
        }
      }

      @include for-tablet-landscape-up {
        &.arrow-left {
          padding: 3em 0 0 1em;
        }

        &.arrow-right {
          padding: 3em 1em 0 0;
        }
      }
    }

    .date-time-picker {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 1.5em;

      .datepicker {
        flex-grow: 3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        .day {
          display: flex;
          min-height: 10em;
          text-align: center;
          flex-direction: column;

          &.selected {
            color: $green;
          }

          &.unavailable {
            .day-name,
            .day-number,
            .month-name {
              opacity: 0.45;
            }
          }

          &:not(.selected):not(.unavailable):hover {
            cursor: pointer;
            opacity: 0.7;
          }

          .day-name {
            font-size: 1.3em;
            text-transform: uppercase;
            margin-bottom: 2.5em;
          }

          .day-number {
            font-size: 2.7em;
            margin-bottom: 12px;
          }

          .month-name {
            font-size: 0.9em;
            text-transform: capitalize;
          }

          &:not(:last-child) {
            border-right: 1px solid $grey;
          }

          @include for-tablet-portrait-up {
            padding: 1em;
          }

          @include for-tablet-landscape-up {
            padding: 1.5em;
          }
        }
      }
    }

    .time-picker {
      padding: 1.5em 0;
    }
  }
}
</style>
