<template>
  <div id="order-form">
    <label for="email">{{ $t('enterEmail') }}</label>
    <input
        id="email"
        ref="email"
        type="email"
        v-model="email"
        :class="{invalid: !inputIsFocused && inputIsInvalid}"
        @change="setEmail(email)"
        @focus="inputIsFocused = true"
        @blur="inputIsFocused = false"
    />
    <button class="button" :disabled="inputIsInvalid || purchaseIsStarted" @click="buyTickets">{{ $t('pay') }}</button>
    <span v-if="purchaseIsFailed" class="error">{{ $t('paymentError') }}</span>
    <a @click="$emit('change-quantity')">{{ $t('changeQuantity') }}</a>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "OrderForm",
  data() {
    return {
      email: null,
      inputIsFocused: true,
      purchaseIsFailed: false,
      purchaseIsStarted: false
    }
  },
  computed: {
    ...mapState(['order']),

    inputIsInvalid() {
      return this.emailIsEmpty || this.emailIsIncorrect;
    },

    emailIsEmpty() {
      return !this.email;
    },

    emailIsIncorrect() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.email && !re.test(String(this.email).toLowerCase());
    }
  },
  methods: {
    ...mapMutations(['setEmail']),

    buyTickets() {
      this.purchaseIsStarted = true;
      this.api.buyTickets(this.order)
          .then(() => this.purchaseIsStarted = false)
          .catch(() => this.handleError());
    },

    handleError() {
      this.purchaseIsFailed = true;

      const resetPurchaseStatusOnNextFocus = () => {
        this.purchaseIsFailed = false;
        this.purchaseIsStarted = false;
      };

      this.$refs.email.addEventListener('focus', () => {
        resetPurchaseStatusOnNextFocus();
        this.$refs.email.removeEventListener(resetPurchaseStatusOnNextFocus);
      });
    }
  },
  mounted() {
    this.email = this.order.email;
    this.$refs.email.focus();
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

#order-form {
  width: 33%;
  display: flex;
  font-size: 1em;
  text-align: center;
  align-items: center;
  flex-direction: column;

  & > * {
    width: 100%;
    margin-bottom: 15px;
  }

  label {
    text-align: center;
  }

  button[disabled] {
    opacity: 0.5 !important;
  }

  input {
    border: none;
    outline: none;
    font-size: 1em;
    text-align: center;
    border-bottom: 1px solid $grey;

    &:focus {
      border-bottom: 1px solid $green;
    }

    &.invalid {
      color: red;
      border-color: red;
    }
  }

  .error {
    color: red;
  }
}
</style>
