<template>
  <div id="time-picker">
    <div>
      <div class="session-wrapper" v-for="session in sortedSessions">
        <div
            class="button session"
            :class="{ selected: session === selectedTime }"
            @click="selectTime(session)"
        >
          {{ session | formatTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import DateTimeHelper from "@/services/DateTimeHelper";

export default {
  name: "TimePicker",
  filters: {
    formatTime: (time) => DateTimeHelper.moscowDate(time).format('HH:mm')
  },
  computed: {
    ...mapState({
      selectedTime: state => state.order.time,
      selectedDaySessions: state => state.selectedDaySessions
    }),
    sortedSessions() {
      return Object.keys(this.selectedDaySessions).sort()
    }
  },
  methods: mapActions(['selectTime'])
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

#time-picker {

  > div {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .session-wrapper {
      padding: 0 8px;
    }

    .session {
      width: 125px;
      margin: 8px 0;

      &.selected {
        color: $green;
        background: $white;
      }
    }
  }
}
</style>