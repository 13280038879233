import moment from 'moment-timezone';

export default class DateTimeHelper {

  static moscowDate(date) {
    return moment(date || new Date()).tz('Europe/Moscow');
  }

  static moscowStartOfWeek(weekNumber) {
    return DateTimeHelper
      .moscowDate()
      .startOf('year')
      .add(weekNumber, 'weeks')
      .startOf('week');
  }

  static moscowStartOfMonth(monthNumber) {
    return DateTimeHelper
      .moscowDate()
      .startOf('year')
      .add(monthNumber, 'months')
      .startOf('month');
  }

}