<template>
  <div id="tariffs-table" class="container" v-if="tariffs.length">
    <p class="subtitle">{{ $t('selectTypeAndQuantity') }}</p>
    <div class="block">
      <table cellspacing="0" class="table" v-if="selectionMode">
        <thead>
        <tr class="bold-text">
          <th>{{ $t('tariff') }}</th>
          <th>{{ $t('quantity') }}</th>
          <th>{{ $t('amount') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tariff in sortedTariffs">
          <td>
            {{ $t(tariff.name) }}
            <!-- <div :class="{
              'tariff-limit': true,
              'sold-out': !tariff.limit
            }">
              {{ tariff.limit ? `Осталось ${tariff.limit} шт.` : 'Нет билетов' }}
            </div> -->
          </td>
          <td>
            <counter
                :value="$store.getters.orderedQuantity(tariff)"
                :editable="!tariff.preferential"
                @on-change="quantity => changeQuantity({tariff, quantity})"
            ></counter>
          </td>
          <td>
            <span class="amount bold-text">{{ tariff.price }}<span class="r">₽</span></span>
          </td>
          <td>
            <button class="button purchase" v-if="!tariff.preferential" :disabled="purchaseButtonsIsDisabled" @click="endSelection">
              {{ $t('purchaseOnline') }}
            </button>
            <button class="button purchase" v-else disabled>
              {{ $t('purchasePersonally') }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else>
        <table cellspacing="0" class="table order">
          <thead>
          <tr class="bold-text">
            <th>{{ $t('tariff') }}</th>
            <th>{{ $t('quantity') }}</th>
            <th>{{ $t('amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="position in tickets">
            <td>{{ $t(position.tariff.name) }}</td>
            <td>{{ position.quantity }}</td>
            <td>{{ position.tariff.price * position.quantity }} ₽</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ $t('overallAmount') }}</td>
            <td>{{ overallAmount }} ₽</td>
          </tr>
          </tbody>
        </table>
        <div class="form-wrapper">
          <order-form @change-quantity="startSelection"></order-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import Counter from '@/components/Counter';

import {
  mapState,
  mapActions,
  mapMutations, mapGetters
} from 'vuex';
import OrderForm from "@/components/OrderForm";

export default {
  name: "TariffsTable",
  components: {
    OrderForm,
    Counter
  },
  data() {
    return {
      selectionMode: true
    }
  },
  computed: {

    ...mapState({
      tariffs: state => state.selectedTimeTariffs,
      tickets: state => state.order.tickets,
      order: state => state.order
    }),

    ...mapGetters([
        'overallAmount'
    ]),

    sortedTariffs() {

      Promise.all(this.tariffs.map((t) => this.api.getTariffLimit(t.time.toDate(), t.id)))
          .then((data) => console.log(data));

      return lodash.sortBy(this.tariffs, 'preferential');
    },

    purchaseButtonsIsDisabled() {
      return !Object.keys(this.tickets).length;
    }
  },
  methods: {

    ...mapGetters(['orderedQuantity']),

    ...mapMutations(['setEmail']),

    ...mapActions(['changeQuantity']),

    startSelection() {
      this.selectionMode = true;
    },

    endSelection() {
      this.selectionMode = false;
    }
  },
  watch: {
    tickets() {
      if (!Object.keys(this.tickets).length) this.startSelection();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

#tariffs-table {

  .block {
    padding: 0;
    flex-direction: column;
  }

  .table {

    width: 100%;

    th {
      font-size: 1.3em;
    }

    td, th {
      padding: 30px 15px;
      text-align: center;
      border-bottom: 1px solid $grey;

      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 20%;
      }

      &:first-child {
        text-align: left;
        padding-left: 45px;
      }

      &:last-child {
        padding-right: 45px;
      }
    }

    td {
      font-size: 1em;
    }

    thead tr:first-child th {
      padding-top: 45px;
    }

    tbody tr:last-child td {
      border: none;
      padding-bottom: 45px;
    }

    .tariff-limit {
      width: 75px;
      text-align: center;
      margin-top: 8px;
      font-size: .8em;
      font-weight: bolder;
      border-radius: 4px;
      padding: 6px;
      background: $green;
      color: $white;

      &.sold-out {
        background: lightgrey;
        width: 100px;
      }
    }

    .amount,
    .counter,
    .purchase {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .amount {
      .r {
        margin-left: 4px;
        font-family: sans-serif;
        font-weight: 900;
      }
    }

    &.order {

      thead tr:first-child {
        color: $white;
        background: -webkit-linear-gradient(left, $green, $blue);
      }

      td, th {
        &:nth-child(1) {
          width: 100%;
        }
      }
    }

  }

  .form-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>