import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  ru: {
    getTickets: 'Приобретение билетов',
    selectDateAndTime: 'Выберите дату и время',
    selectTypeAndQuantity: 'Выберите количество и тип билетов',
    tariff: 'Тип билета',
    quantity: 'Количество',
    amount: 'Стоимость',
    purchaseOnline: 'Купить онлайн',
    purchasePersonally: 'В кассе',
    enterEmail: 'Укажите вашу электронную почту:',
    emailIsEmpty: 'Это обязательное поле.',
    emailIsIncorrect: 'Пожалуйста, введите корректный e-mail.',
    overallAmount: 'К оплате:',
    pay: 'Оплатить',
    checkout: 'Оплата и получение билетов',
    pcs: 'шт.',
    changeQuantity: 'Изменить количество билетов',
    paymentError: 'Возникла ошибка при оплате',
    pleaseWaitAndTryAgain: 'Попробуйте повторить оплату немного позже',
    paymentSuccess: 'Оплата прошла успешно',
    ticketsWasSentToEmail: 'Билеты были отправлены на вашу электронную почту',
    linkToTicketPreparing: 'Пожалуйста, подождите, ссылка для скачивания вашего билета подготавливается',
    downloadTickets: 'Загрузить билеты',
    backToMainPage: 'Вернуться на главную',
    contactsLabel: 'Забронировать и приобрести групповые билеты для посещения ЭПЦ Яуза можно по телефону <a type="tel" href="tel:{telValue}">{telLabel}</a> или по электронной почте <a type="email" href="mailto:{email}">{email}</a>.',
    contactsLabelAddition1: 'Посещение для детей до 5 лет - бесплатно.',
    contactsLabelAddition2: 'К льготной группе граждан относятся: многодетные семьи, дети-сироты, люди с ограниченными возможностями, пенсионеры, ветераны боевых действий, участники ликвидации последствий катастрофы на чернобыльской АЭС.',
    phoneNumber: '{main} доб. {ext}',
    privacyPolicy: 'Политика конфиденциальности',
    noAvailableTicketsForEarlierDates: 'Извините, в продаже нет билетов на более ранние даты.',
    errorLoadingTickers: 'Произошла ошибка при поиске билетов. </br>Пожалуйста, повторите попытку позже',
    contacts: 'Государственное автономное учреждение города Москвы "Парк Яуза" тел: +7 (499) 476-22-59, Юридический адрес: г. Москва ул. Тихомирова дом 12 корпус 1, Фактический адрес: г. Москва Юрловский проезд вл.10/4, ИНН 9715365510'
  },

  en: {
    getTickets: 'Buying tickets',
    selectDateAndTime: 'Select the date and time',
    selectTypeAndQuantity: 'Select the number and type of ticket ',
    tariff: 'Ticket type',
    quantity: 'Number',
    amount: 'Cost',
    purchaseOnline: 'Buy Online',
    purchasePersonally: 'At the ticket desk',
    enterEmail: 'Enter your email:',
    emailIsEmpty: 'Required field.',
    emailIsIncorrect: 'Please enter a valid e-mail.',
    overallAmount: 'Payable amount:',
    pay: 'Pay',
    checkout: 'Payment and receipt of tickets',
    pcs: 'pcs.',
    changeQuantity: 'Change the number of tickets',
    paymentError: 'An error occurred during processing',
    pleaseWaitAndTryAgain: 'Try later',
    paymentSuccess: 'The payment was done successfully',
    ticketsWasSentToEmail: 'Tickets have been sent to your email',
    linkToTicketPreparing: 'Please wait, the download link for your ticket is being prepared',
    downloadTickets: 'Download tickets',
    backToMainPage: 'Go back to the homepage',
    contactsLabel: 'Book and purchase collective tickets to visit the environmental education center Yauza by phone <a type="tel" href="tel:{telValue}">{telLabel}</a> or by email <a type="email" href="mailto:{email}">{email}</a>.',
    contactsLabelAddition1: 'Attendance for children under 5 years is free.',
    contactsLabelAddition2: 'The privileged group of citizens includes large families, orphaned children, disabled, pensioners, combat veterans, participants in the disaster mitigation of the Chernobyl Nuclear Plant.',
    phoneNumber: '{main} ext. {ext}',
    privacyPolicy: 'Privacy policy',
    noAvailableTicketsForEarlierDates: 'Sorry, there are no tickets for earlier dates.',
    errorLoadingTickers: 'An error occurred while searching for tickets. </br>Please try again later',
    contacts: 'State Autonomous Institution of the City of Moscow "Park Yauza" tel: +7 (499) 476-22-59, Legal address: Moscow st. Tikhomirov building 12 building 1, Actual address: Moscow Yurlovsky proezd vl.10/4, TIN 9715365510',

    'Единый билет (будни)': 'Entire ticket (weekdays)',
    'Единый билет (выходные)': 'Entire ticket (weekends)',
    'Экопрогулки с элементами квеста по парку': 'Eco-quest walks on the territory of Yauza Park',
    'Экскурсия в группе': 'Group tour'
  },

  zh: {
    getTickets: '购票',
    selectDateAndTime: '选择日期和时间',
    selectTypeAndQuantity: '选择票的数量和类型',
    tariff: '门票的类型',
    quantity: '数量',
    amount: '价格',
    purchaseOnline: '网上购票',
    purchasePersonally: '在售票处购票',
    enterEmail: '输入您的电子邮箱:',
    emailIsEmpty: '必填字段。',
    emailIsIncorrect: '请输入有效的电子邮箱。',
    overallAmount: '支付金额:',
    pay: '支付',
    checkout: '门票的支付与领取',
    pcs: '個。',
    changeQuantity: '更改门票数量',
    paymentError: '支付出错',
    pleaseWaitAndTryAgain: '稍后尝试重复支付',
    paymentSuccess: '支付成功',
    ticketsWasSentToEmail: '门票已发送至您的电子邮箱',
    linkToTicketPreparing: '请稍候，门票下载链接正在准备中',
    downloadTickets: '上传门票',
    backToMainPage: '返回主页面',
    contactsLabel: '您可以用电话 <a type="tel" href="tel:{telValue}">{telLabel}</a> 或用电子邮件 <a type="email" href="mailto:{email}">{email}</a> 预订和购买参观亚乌扎生态中心的团体票。',
    contactsLabelAddition1: '5 岁以下儿童免费。',
    contactsLabelAddition2: '优待客包括: 多子女家庭、孤儿、残疾人、领退休金的人、退伍军人、切尔诺贝利核电站灾难后果消灭参与者。',
    phoneNumber: '{main} ({ext})',
    privacyPolicy: '私隐政策',
    noAvailableTicketsForEarlierDates: '对不起,较早的日期没有门票出售.',
    errorLoadingTickers: '搜索票时出错。 请稍后再试',
    contacts: '莫斯科市国家自治机构“Park Yauza”电话: +7 (499) 476-22-59, 法定地址: Moscow st. Tikhomirov 12号楼1号楼, 实际地址: Moscow Yurlovsky proezd vl.10/4, TIN 9715365510',

    'Единый билет (будни)': '全票(平日)',
    'Единый билет (выходные)': '全票(周末)',
    'Экопрогулки с элементами квеста по парку': '有探索生态的游玩在Yauza公园',
    'Экскурсия в группе': '团体旅游'
  }
};

export const i18n = new VueI18n({
  messages,
  fallbackLocale: 'ru'
});
